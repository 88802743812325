/** Space for all imported CSS Libraries **/

@use '@aegis-framework/kayros.css';

/** Space for all imported Fonts **/

@font-face {
	font-family: 'Ubuntu';
	font-display: fallback;
	src: url(./../assets/fonts/Ubuntu-L.ttf);
	font-weight: lighter;
}

/** Main Style goes here**/

:root {
	color-scheme: light dark;

	--color-background: linear-gradient(90deg, #f5f5f5 1em, transparent 1%) center, linear-gradient(#f5f5f5  1em, transparent 1%) center, #bbb;
	--color-text: #424242;
}

@media (prefers-color-scheme: dark) {
	:root {
		--color-text: #ddd;
		--color-background: linear-gradient(90deg, #222 1em, transparent 1%) center, linear-gradient(#222  1em, transparent 1%) center, #424242;;
	}
}
  
body {
	font-family: 'Ubuntu', 'Open-Sans', sans-serif;
	background: var(--color-background);
	background-size: 1.1em 1.1em;
	color: var(--color-text);
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	margin: 0;
	transition: 1s;
	flex-direction: column;
}

h1 {
	font-weight: lighter;
	font-size: 5em;
	text-align: center;
	margin: 0;
}

h2 {
	font-weight: lighter;
	font-size: 3em;
	text-align: center;
	font-style: italic;
}

small {
	display: block;
	font-size: 0.8rem
}

/** Place here all Media Queries and it's styles.**/

/** Default Style. Don't change. (320px) **/
@media screen and  (max-width: 1) {}

/** Extra Small Devices, Phones (480px) **/
@media screen and (min-width : 30dvw) {

}

/** Medium Screens, Tablets (601px) **/
@media screen and (min-width: 37.56255em) {

}

/** Medium Devices, Desktops (992px)**/
@media screen and (min-width: 62em) {

}

/** HD Screen, Large Devices, Wide Screens (1200px) **/
@media screen and (min-width: 75em) {

}

/** Full HD Screen, Large Devices, Wide Screens (1920px) **/
@media screen and (min-width: 120em) {

}

/** Retina Screen , Large Devices, Wide Screens(2560px) **/
@media screen and (min-width: 160em) {

}

/** 4k Screens, Large Devices, Wide Screens (3840px) **/
@media screen and (min-width: 240em) {

}

/** 5k Screens, Large Devices, Wide Screens (5120px) **/
@media screen and (min-width: 312.15em) {

}

/** CSS for Printing **/
@media print {

}
