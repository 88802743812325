:root {
  --kayros_grid-gutter: 1.5rem;
  --kayros_breakpoint-phone: 480px;
  --kayros_breakpoint-phablet: 601px;
  --kayros_breakpoint-tablet: 992px;
  --kayros_breakpoint-desktop: 1200px;
  --kayros_breakpoint-desktop-large: 1900px;
  --kayros_breakpoint-retina: 2500px;
  --kayros_breakpoint-4k: 3840px;
  --kayros_breakpoint-5k: 5000px;
  --kayros_breakpoint-8k: 8000px;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  width: 100%;
  height: 100%;
  font-family: sans-serif;
  overflow-x: hidden;
}

body {
  color: #424242;
  width: 100%;
  height: 100%;
  text-rendering: optimizelegibility;
  margin: 0;
  padding: 0;
  font-size: 100%;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section {
  display: block;
}

summary {
  display: list-item;
}

audio, canvas, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

progress {
  vertical-align: baseline;
}

template, [hidden] {
  display: none;
}

a {
  color: inherit;
  cursor: pointer;
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
  text-decoration: none;
}

a * {
  cursor: pointer;
}

a:active, a:hover {
  outline-width: 0;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

dfn {
  font-style: italic;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
  line-height: 1.25em;
}

h2 {
  font-size: 1.8em;
}

h3 {
  font-size: 1.6em;
}

h4 {
  font-size: 1.4em;
}

h5 {
  font-size: 1.2em;
}

h6 {
  font-size: 1em;
}

mark {
  color: #000;
  background-color: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border: 0;
  max-width: 100%;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  border: 1px solid #424242;
  padding: .5em;
}

.unselectable, img {
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -o-user-select: none;
  -webkit-user-drag: none;
}

*, :before, :after {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  background: none;
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: #0003;
  border-radius: 3px;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

::selection {
  background: #00bcd433;
}

.row {
  flex-flow: wrap;
  place-content: stretch center;
  align-items: stretch;
  width: 100%;
  display: flex;
}

.row--spaced > .row__column {
  margin: .75rem;
}

.row--stretch {
  align-content: stretch;
}

.row--around {
  align-content: space-around;
}

.row--align-left {
  justify-content: flex-start;
}

.row--align-right {
  justify-content: flex-end;
}

.row--align-center-between {
  justify-content: space-between;
}

.row--align-center-around {
  justify-content: space-around;
}

.row__column {
  flex: none;
  align-self: center;
  max-width: 100%;
}

.row__column--1 {
  width: 8.33333%;
}

.row__column--offset--1 {
  margin-left: 8.33333%;
}

.row--spaced > .row__column--1 {
  width: calc(8.33333% - var(--kayros_grid-gutter));
}

.row--spaced > .row__column--offset--1 {
  margin-left: calc(8.33333% - var(--kayros_grid-gutter));
}

.row__column--2 {
  width: 16.6667%;
}

.row__column--offset--2 {
  margin-left: 16.6667%;
}

.row--spaced > .row__column--2 {
  width: calc(16.6667% - var(--kayros_grid-gutter));
}

.row--spaced > .row__column--offset--2 {
  margin-left: calc(16.6667% - var(--kayros_grid-gutter));
}

.row__column--3 {
  width: 25%;
}

.row__column--offset--3 {
  margin-left: 25%;
}

.row--spaced > .row__column--3 {
  width: calc(25% - var(--kayros_grid-gutter));
}

.row--spaced > .row__column--offset--3 {
  margin-left: calc(25% - var(--kayros_grid-gutter));
}

.row__column--4 {
  width: 33.3333%;
}

.row__column--offset--4 {
  margin-left: 33.3333%;
}

.row--spaced > .row__column--4 {
  width: calc(33.3333% - var(--kayros_grid-gutter));
}

.row--spaced > .row__column--offset--4 {
  margin-left: calc(33.3333% - var(--kayros_grid-gutter));
}

.row__column--5 {
  width: 41.6667%;
}

.row__column--offset--5 {
  margin-left: 41.6667%;
}

.row--spaced > .row__column--5 {
  width: calc(41.6667% - var(--kayros_grid-gutter));
}

.row--spaced > .row__column--offset--5 {
  margin-left: calc(41.6667% - var(--kayros_grid-gutter));
}

.row__column--6 {
  width: 50%;
}

.row__column--offset--6 {
  margin-left: 50%;
}

.row--spaced > .row__column--6 {
  width: calc(50% - var(--kayros_grid-gutter));
}

.row--spaced > .row__column--offset--6 {
  margin-left: calc(50% - var(--kayros_grid-gutter));
}

.row__column--7 {
  width: 58.3333%;
}

.row__column--offset--7 {
  margin-left: 58.3333%;
}

.row--spaced > .row__column--7 {
  width: calc(58.3333% - var(--kayros_grid-gutter));
}

.row--spaced > .row__column--offset--7 {
  margin-left: calc(58.3333% - var(--kayros_grid-gutter));
}

.row__column--8 {
  width: 66.6667%;
}

.row__column--offset--8 {
  margin-left: 66.6667%;
}

.row--spaced > .row__column--8 {
  width: calc(66.6667% - var(--kayros_grid-gutter));
}

.row--spaced > .row__column--offset--8 {
  margin-left: calc(66.6667% - var(--kayros_grid-gutter));
}

.row__column--9 {
  width: 75%;
}

.row__column--offset--9 {
  margin-left: 75%;
}

.row--spaced > .row__column--9 {
  width: calc(75% - var(--kayros_grid-gutter));
}

.row--spaced > .row__column--offset--9 {
  margin-left: calc(75% - var(--kayros_grid-gutter));
}

.row__column--10 {
  width: 83.3333%;
}

.row__column--offset--10 {
  margin-left: 83.3333%;
}

.row--spaced > .row__column--10 {
  width: calc(83.3333% - var(--kayros_grid-gutter));
}

.row--spaced > .row__column--offset--10 {
  margin-left: calc(83.3333% - var(--kayros_grid-gutter));
}

.row__column--11 {
  width: 91.6667%;
}

.row__column--offset--11 {
  margin-left: 91.6667%;
}

.row--spaced > .row__column--11 {
  width: calc(91.6667% - var(--kayros_grid-gutter));
}

.row--spaced > .row__column--offset--11 {
  margin-left: calc(91.6667% - var(--kayros_grid-gutter));
}

.row__column--12 {
  width: 100%;
}

.row__column--offset--12 {
  margin-left: 100%;
}

.row--spaced > .row__column--12 {
  width: calc(100% - var(--kayros_grid-gutter));
}

.row--spaced > .row__column--offset--12 {
  margin-left: calc(100% - var(--kayros_grid-gutter));
}

.row__column--auto {
  flex: 1 1 0 !important;
}

@media (width >= 480px) {
  .row__column--phone--1 {
    width: 8.33333%;
  }

  .row__column--phone--offset--1 {
    margin-left: 8.33333%;
  }

  .row--spaced > .row__column--phone--1 {
    width: calc(8.33333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--phone--offset--1 {
    margin-left: calc(8.33333% - var(--kayros_grid-gutter));
  }

  .row__column--phone--2 {
    width: 16.6667%;
  }

  .row__column--phone--offset--2 {
    margin-left: 16.6667%;
  }

  .row--spaced > .row__column--phone--2 {
    width: calc(16.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--phone--offset--2 {
    margin-left: calc(16.6667% - var(--kayros_grid-gutter));
  }

  .row__column--phone--3 {
    width: 25%;
  }

  .row__column--phone--offset--3 {
    margin-left: 25%;
  }

  .row--spaced > .row__column--phone--3 {
    width: calc(25% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--phone--offset--3 {
    margin-left: calc(25% - var(--kayros_grid-gutter));
  }

  .row__column--phone--4 {
    width: 33.3333%;
  }

  .row__column--phone--offset--4 {
    margin-left: 33.3333%;
  }

  .row--spaced > .row__column--phone--4 {
    width: calc(33.3333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--phone--offset--4 {
    margin-left: calc(33.3333% - var(--kayros_grid-gutter));
  }

  .row__column--phone--5 {
    width: 41.6667%;
  }

  .row__column--phone--offset--5 {
    margin-left: 41.6667%;
  }

  .row--spaced > .row__column--phone--5 {
    width: calc(41.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--phone--offset--5 {
    margin-left: calc(41.6667% - var(--kayros_grid-gutter));
  }

  .row__column--phone--6 {
    width: 50%;
  }

  .row__column--phone--offset--6 {
    margin-left: 50%;
  }

  .row--spaced > .row__column--phone--6 {
    width: calc(50% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--phone--offset--6 {
    margin-left: calc(50% - var(--kayros_grid-gutter));
  }

  .row__column--phone--7 {
    width: 58.3333%;
  }

  .row__column--phone--offset--7 {
    margin-left: 58.3333%;
  }

  .row--spaced > .row__column--phone--7 {
    width: calc(58.3333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--phone--offset--7 {
    margin-left: calc(58.3333% - var(--kayros_grid-gutter));
  }

  .row__column--phone--8 {
    width: 66.6667%;
  }

  .row__column--phone--offset--8 {
    margin-left: 66.6667%;
  }

  .row--spaced > .row__column--phone--8 {
    width: calc(66.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--phone--offset--8 {
    margin-left: calc(66.6667% - var(--kayros_grid-gutter));
  }

  .row__column--phone--9 {
    width: 75%;
  }

  .row__column--phone--offset--9 {
    margin-left: 75%;
  }

  .row--spaced > .row__column--phone--9 {
    width: calc(75% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--phone--offset--9 {
    margin-left: calc(75% - var(--kayros_grid-gutter));
  }

  .row__column--phone--10 {
    width: 83.3333%;
  }

  .row__column--phone--offset--10 {
    margin-left: 83.3333%;
  }

  .row--spaced > .row__column--phone--10 {
    width: calc(83.3333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--phone--offset--10 {
    margin-left: calc(83.3333% - var(--kayros_grid-gutter));
  }

  .row__column--phone--11 {
    width: 91.6667%;
  }

  .row__column--phone--offset--11 {
    margin-left: 91.6667%;
  }

  .row--spaced > .row__column--phone--11 {
    width: calc(91.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--phone--offset--11 {
    margin-left: calc(91.6667% - var(--kayros_grid-gutter));
  }

  .row__column--phone--12 {
    width: 100%;
  }

  .row__column--phone--offset--12 {
    margin-left: 100%;
  }

  .row--spaced > .row__column--phone--12 {
    width: calc(100% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--phone--offset--12 {
    margin-left: calc(100% - var(--kayros_grid-gutter));
  }
}

@media (width >= 601px) {
  .row__column--phablet--1 {
    width: 8.33333%;
  }

  .row__column--phablet--offset--1 {
    margin-left: 8.33333%;
  }

  .row--spaced > .row__column--phablet--1 {
    width: calc(8.33333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--phablet--offset--1 {
    margin-left: calc(8.33333% - var(--kayros_grid-gutter));
  }

  .row__column--phablet--2 {
    width: 16.6667%;
  }

  .row__column--phablet--offset--2 {
    margin-left: 16.6667%;
  }

  .row--spaced > .row__column--phablet--2 {
    width: calc(16.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--phablet--offset--2 {
    margin-left: calc(16.6667% - var(--kayros_grid-gutter));
  }

  .row__column--phablet--3 {
    width: 25%;
  }

  .row__column--phablet--offset--3 {
    margin-left: 25%;
  }

  .row--spaced > .row__column--phablet--3 {
    width: calc(25% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--phablet--offset--3 {
    margin-left: calc(25% - var(--kayros_grid-gutter));
  }

  .row__column--phablet--4 {
    width: 33.3333%;
  }

  .row__column--phablet--offset--4 {
    margin-left: 33.3333%;
  }

  .row--spaced > .row__column--phablet--4 {
    width: calc(33.3333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--phablet--offset--4 {
    margin-left: calc(33.3333% - var(--kayros_grid-gutter));
  }

  .row__column--phablet--5 {
    width: 41.6667%;
  }

  .row__column--phablet--offset--5 {
    margin-left: 41.6667%;
  }

  .row--spaced > .row__column--phablet--5 {
    width: calc(41.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--phablet--offset--5 {
    margin-left: calc(41.6667% - var(--kayros_grid-gutter));
  }

  .row__column--phablet--6 {
    width: 50%;
  }

  .row__column--phablet--offset--6 {
    margin-left: 50%;
  }

  .row--spaced > .row__column--phablet--6 {
    width: calc(50% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--phablet--offset--6 {
    margin-left: calc(50% - var(--kayros_grid-gutter));
  }

  .row__column--phablet--7 {
    width: 58.3333%;
  }

  .row__column--phablet--offset--7 {
    margin-left: 58.3333%;
  }

  .row--spaced > .row__column--phablet--7 {
    width: calc(58.3333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--phablet--offset--7 {
    margin-left: calc(58.3333% - var(--kayros_grid-gutter));
  }

  .row__column--phablet--8 {
    width: 66.6667%;
  }

  .row__column--phablet--offset--8 {
    margin-left: 66.6667%;
  }

  .row--spaced > .row__column--phablet--8 {
    width: calc(66.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--phablet--offset--8 {
    margin-left: calc(66.6667% - var(--kayros_grid-gutter));
  }

  .row__column--phablet--9 {
    width: 75%;
  }

  .row__column--phablet--offset--9 {
    margin-left: 75%;
  }

  .row--spaced > .row__column--phablet--9 {
    width: calc(75% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--phablet--offset--9 {
    margin-left: calc(75% - var(--kayros_grid-gutter));
  }

  .row__column--phablet--10 {
    width: 83.3333%;
  }

  .row__column--phablet--offset--10 {
    margin-left: 83.3333%;
  }

  .row--spaced > .row__column--phablet--10 {
    width: calc(83.3333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--phablet--offset--10 {
    margin-left: calc(83.3333% - var(--kayros_grid-gutter));
  }

  .row__column--phablet--11 {
    width: 91.6667%;
  }

  .row__column--phablet--offset--11 {
    margin-left: 91.6667%;
  }

  .row--spaced > .row__column--phablet--11 {
    width: calc(91.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--phablet--offset--11 {
    margin-left: calc(91.6667% - var(--kayros_grid-gutter));
  }

  .row__column--phablet--12 {
    width: 100%;
  }

  .row__column--phablet--offset--12 {
    margin-left: 100%;
  }

  .row--spaced > .row__column--phablet--12 {
    width: calc(100% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--phablet--offset--12 {
    margin-left: calc(100% - var(--kayros_grid-gutter));
  }
}

@media (width >= 992px) {
  .row__column--tablet--1 {
    width: 8.33333%;
  }

  .row__column--tablet--offset--1 {
    margin-left: 8.33333%;
  }

  .row--spaced > .row__column--tablet--1 {
    width: calc(8.33333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--tablet--offset--1 {
    margin-left: calc(8.33333% - var(--kayros_grid-gutter));
  }

  .row__column--tablet--2 {
    width: 16.6667%;
  }

  .row__column--tablet--offset--2 {
    margin-left: 16.6667%;
  }

  .row--spaced > .row__column--tablet--2 {
    width: calc(16.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--tablet--offset--2 {
    margin-left: calc(16.6667% - var(--kayros_grid-gutter));
  }

  .row__column--tablet--3 {
    width: 25%;
  }

  .row__column--tablet--offset--3 {
    margin-left: 25%;
  }

  .row--spaced > .row__column--tablet--3 {
    width: calc(25% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--tablet--offset--3 {
    margin-left: calc(25% - var(--kayros_grid-gutter));
  }

  .row__column--tablet--4 {
    width: 33.3333%;
  }

  .row__column--tablet--offset--4 {
    margin-left: 33.3333%;
  }

  .row--spaced > .row__column--tablet--4 {
    width: calc(33.3333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--tablet--offset--4 {
    margin-left: calc(33.3333% - var(--kayros_grid-gutter));
  }

  .row__column--tablet--5 {
    width: 41.6667%;
  }

  .row__column--tablet--offset--5 {
    margin-left: 41.6667%;
  }

  .row--spaced > .row__column--tablet--5 {
    width: calc(41.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--tablet--offset--5 {
    margin-left: calc(41.6667% - var(--kayros_grid-gutter));
  }

  .row__column--tablet--6 {
    width: 50%;
  }

  .row__column--tablet--offset--6 {
    margin-left: 50%;
  }

  .row--spaced > .row__column--tablet--6 {
    width: calc(50% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--tablet--offset--6 {
    margin-left: calc(50% - var(--kayros_grid-gutter));
  }

  .row__column--tablet--7 {
    width: 58.3333%;
  }

  .row__column--tablet--offset--7 {
    margin-left: 58.3333%;
  }

  .row--spaced > .row__column--tablet--7 {
    width: calc(58.3333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--tablet--offset--7 {
    margin-left: calc(58.3333% - var(--kayros_grid-gutter));
  }

  .row__column--tablet--8 {
    width: 66.6667%;
  }

  .row__column--tablet--offset--8 {
    margin-left: 66.6667%;
  }

  .row--spaced > .row__column--tablet--8 {
    width: calc(66.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--tablet--offset--8 {
    margin-left: calc(66.6667% - var(--kayros_grid-gutter));
  }

  .row__column--tablet--9 {
    width: 75%;
  }

  .row__column--tablet--offset--9 {
    margin-left: 75%;
  }

  .row--spaced > .row__column--tablet--9 {
    width: calc(75% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--tablet--offset--9 {
    margin-left: calc(75% - var(--kayros_grid-gutter));
  }

  .row__column--tablet--10 {
    width: 83.3333%;
  }

  .row__column--tablet--offset--10 {
    margin-left: 83.3333%;
  }

  .row--spaced > .row__column--tablet--10 {
    width: calc(83.3333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--tablet--offset--10 {
    margin-left: calc(83.3333% - var(--kayros_grid-gutter));
  }

  .row__column--tablet--11 {
    width: 91.6667%;
  }

  .row__column--tablet--offset--11 {
    margin-left: 91.6667%;
  }

  .row--spaced > .row__column--tablet--11 {
    width: calc(91.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--tablet--offset--11 {
    margin-left: calc(91.6667% - var(--kayros_grid-gutter));
  }

  .row__column--tablet--12 {
    width: 100%;
  }

  .row__column--tablet--offset--12 {
    margin-left: 100%;
  }

  .row--spaced > .row__column--tablet--12 {
    width: calc(100% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--tablet--offset--12 {
    margin-left: calc(100% - var(--kayros_grid-gutter));
  }
}

@media (width >= 1200px) {
  .row__column--desktop--1 {
    width: 8.33333%;
  }

  .row__column--desktop--offset--1 {
    margin-left: 8.33333%;
  }

  .row--spaced > .row__column--desktop--1 {
    width: calc(8.33333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--desktop--offset--1 {
    margin-left: calc(8.33333% - var(--kayros_grid-gutter));
  }

  .row__column--desktop--2 {
    width: 16.6667%;
  }

  .row__column--desktop--offset--2 {
    margin-left: 16.6667%;
  }

  .row--spaced > .row__column--desktop--2 {
    width: calc(16.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--desktop--offset--2 {
    margin-left: calc(16.6667% - var(--kayros_grid-gutter));
  }

  .row__column--desktop--3 {
    width: 25%;
  }

  .row__column--desktop--offset--3 {
    margin-left: 25%;
  }

  .row--spaced > .row__column--desktop--3 {
    width: calc(25% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--desktop--offset--3 {
    margin-left: calc(25% - var(--kayros_grid-gutter));
  }

  .row__column--desktop--4 {
    width: 33.3333%;
  }

  .row__column--desktop--offset--4 {
    margin-left: 33.3333%;
  }

  .row--spaced > .row__column--desktop--4 {
    width: calc(33.3333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--desktop--offset--4 {
    margin-left: calc(33.3333% - var(--kayros_grid-gutter));
  }

  .row__column--desktop--5 {
    width: 41.6667%;
  }

  .row__column--desktop--offset--5 {
    margin-left: 41.6667%;
  }

  .row--spaced > .row__column--desktop--5 {
    width: calc(41.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--desktop--offset--5 {
    margin-left: calc(41.6667% - var(--kayros_grid-gutter));
  }

  .row__column--desktop--6 {
    width: 50%;
  }

  .row__column--desktop--offset--6 {
    margin-left: 50%;
  }

  .row--spaced > .row__column--desktop--6 {
    width: calc(50% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--desktop--offset--6 {
    margin-left: calc(50% - var(--kayros_grid-gutter));
  }

  .row__column--desktop--7 {
    width: 58.3333%;
  }

  .row__column--desktop--offset--7 {
    margin-left: 58.3333%;
  }

  .row--spaced > .row__column--desktop--7 {
    width: calc(58.3333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--desktop--offset--7 {
    margin-left: calc(58.3333% - var(--kayros_grid-gutter));
  }

  .row__column--desktop--8 {
    width: 66.6667%;
  }

  .row__column--desktop--offset--8 {
    margin-left: 66.6667%;
  }

  .row--spaced > .row__column--desktop--8 {
    width: calc(66.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--desktop--offset--8 {
    margin-left: calc(66.6667% - var(--kayros_grid-gutter));
  }

  .row__column--desktop--9 {
    width: 75%;
  }

  .row__column--desktop--offset--9 {
    margin-left: 75%;
  }

  .row--spaced > .row__column--desktop--9 {
    width: calc(75% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--desktop--offset--9 {
    margin-left: calc(75% - var(--kayros_grid-gutter));
  }

  .row__column--desktop--10 {
    width: 83.3333%;
  }

  .row__column--desktop--offset--10 {
    margin-left: 83.3333%;
  }

  .row--spaced > .row__column--desktop--10 {
    width: calc(83.3333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--desktop--offset--10 {
    margin-left: calc(83.3333% - var(--kayros_grid-gutter));
  }

  .row__column--desktop--11 {
    width: 91.6667%;
  }

  .row__column--desktop--offset--11 {
    margin-left: 91.6667%;
  }

  .row--spaced > .row__column--desktop--11 {
    width: calc(91.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--desktop--offset--11 {
    margin-left: calc(91.6667% - var(--kayros_grid-gutter));
  }

  .row__column--desktop--12 {
    width: 100%;
  }

  .row__column--desktop--offset--12 {
    margin-left: 100%;
  }

  .row--spaced > .row__column--desktop--12 {
    width: calc(100% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--desktop--offset--12 {
    margin-left: calc(100% - var(--kayros_grid-gutter));
  }
}

@media (width >= 1900px) {
  .row__column--desktop-large--1 {
    width: 8.33333%;
  }

  .row__column--desktop-large--offset--1 {
    margin-left: 8.33333%;
  }

  .row--spaced > .row__column--desktop-large--1 {
    width: calc(8.33333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--desktop-large--offset--1 {
    margin-left: calc(8.33333% - var(--kayros_grid-gutter));
  }

  .row__column--desktop-large--2 {
    width: 16.6667%;
  }

  .row__column--desktop-large--offset--2 {
    margin-left: 16.6667%;
  }

  .row--spaced > .row__column--desktop-large--2 {
    width: calc(16.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--desktop-large--offset--2 {
    margin-left: calc(16.6667% - var(--kayros_grid-gutter));
  }

  .row__column--desktop-large--3 {
    width: 25%;
  }

  .row__column--desktop-large--offset--3 {
    margin-left: 25%;
  }

  .row--spaced > .row__column--desktop-large--3 {
    width: calc(25% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--desktop-large--offset--3 {
    margin-left: calc(25% - var(--kayros_grid-gutter));
  }

  .row__column--desktop-large--4 {
    width: 33.3333%;
  }

  .row__column--desktop-large--offset--4 {
    margin-left: 33.3333%;
  }

  .row--spaced > .row__column--desktop-large--4 {
    width: calc(33.3333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--desktop-large--offset--4 {
    margin-left: calc(33.3333% - var(--kayros_grid-gutter));
  }

  .row__column--desktop-large--5 {
    width: 41.6667%;
  }

  .row__column--desktop-large--offset--5 {
    margin-left: 41.6667%;
  }

  .row--spaced > .row__column--desktop-large--5 {
    width: calc(41.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--desktop-large--offset--5 {
    margin-left: calc(41.6667% - var(--kayros_grid-gutter));
  }

  .row__column--desktop-large--6 {
    width: 50%;
  }

  .row__column--desktop-large--offset--6 {
    margin-left: 50%;
  }

  .row--spaced > .row__column--desktop-large--6 {
    width: calc(50% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--desktop-large--offset--6 {
    margin-left: calc(50% - var(--kayros_grid-gutter));
  }

  .row__column--desktop-large--7 {
    width: 58.3333%;
  }

  .row__column--desktop-large--offset--7 {
    margin-left: 58.3333%;
  }

  .row--spaced > .row__column--desktop-large--7 {
    width: calc(58.3333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--desktop-large--offset--7 {
    margin-left: calc(58.3333% - var(--kayros_grid-gutter));
  }

  .row__column--desktop-large--8 {
    width: 66.6667%;
  }

  .row__column--desktop-large--offset--8 {
    margin-left: 66.6667%;
  }

  .row--spaced > .row__column--desktop-large--8 {
    width: calc(66.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--desktop-large--offset--8 {
    margin-left: calc(66.6667% - var(--kayros_grid-gutter));
  }

  .row__column--desktop-large--9 {
    width: 75%;
  }

  .row__column--desktop-large--offset--9 {
    margin-left: 75%;
  }

  .row--spaced > .row__column--desktop-large--9 {
    width: calc(75% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--desktop-large--offset--9 {
    margin-left: calc(75% - var(--kayros_grid-gutter));
  }

  .row__column--desktop-large--10 {
    width: 83.3333%;
  }

  .row__column--desktop-large--offset--10 {
    margin-left: 83.3333%;
  }

  .row--spaced > .row__column--desktop-large--10 {
    width: calc(83.3333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--desktop-large--offset--10 {
    margin-left: calc(83.3333% - var(--kayros_grid-gutter));
  }

  .row__column--desktop-large--11 {
    width: 91.6667%;
  }

  .row__column--desktop-large--offset--11 {
    margin-left: 91.6667%;
  }

  .row--spaced > .row__column--desktop-large--11 {
    width: calc(91.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--desktop-large--offset--11 {
    margin-left: calc(91.6667% - var(--kayros_grid-gutter));
  }

  .row__column--desktop-large--12 {
    width: 100%;
  }

  .row__column--desktop-large--offset--12 {
    margin-left: 100%;
  }

  .row--spaced > .row__column--desktop-large--12 {
    width: calc(100% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--desktop-large--offset--12 {
    margin-left: calc(100% - var(--kayros_grid-gutter));
  }
}

@media (width >= 2500px) {
  .row__column--retina--1 {
    width: 8.33333%;
  }

  .row__column--retina--offset--1 {
    margin-left: 8.33333%;
  }

  .row--spaced > .row__column--retina--1 {
    width: calc(8.33333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--retina--offset--1 {
    margin-left: calc(8.33333% - var(--kayros_grid-gutter));
  }

  .row__column--retina--2 {
    width: 16.6667%;
  }

  .row__column--retina--offset--2 {
    margin-left: 16.6667%;
  }

  .row--spaced > .row__column--retina--2 {
    width: calc(16.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--retina--offset--2 {
    margin-left: calc(16.6667% - var(--kayros_grid-gutter));
  }

  .row__column--retina--3 {
    width: 25%;
  }

  .row__column--retina--offset--3 {
    margin-left: 25%;
  }

  .row--spaced > .row__column--retina--3 {
    width: calc(25% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--retina--offset--3 {
    margin-left: calc(25% - var(--kayros_grid-gutter));
  }

  .row__column--retina--4 {
    width: 33.3333%;
  }

  .row__column--retina--offset--4 {
    margin-left: 33.3333%;
  }

  .row--spaced > .row__column--retina--4 {
    width: calc(33.3333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--retina--offset--4 {
    margin-left: calc(33.3333% - var(--kayros_grid-gutter));
  }

  .row__column--retina--5 {
    width: 41.6667%;
  }

  .row__column--retina--offset--5 {
    margin-left: 41.6667%;
  }

  .row--spaced > .row__column--retina--5 {
    width: calc(41.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--retina--offset--5 {
    margin-left: calc(41.6667% - var(--kayros_grid-gutter));
  }

  .row__column--retina--6 {
    width: 50%;
  }

  .row__column--retina--offset--6 {
    margin-left: 50%;
  }

  .row--spaced > .row__column--retina--6 {
    width: calc(50% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--retina--offset--6 {
    margin-left: calc(50% - var(--kayros_grid-gutter));
  }

  .row__column--retina--7 {
    width: 58.3333%;
  }

  .row__column--retina--offset--7 {
    margin-left: 58.3333%;
  }

  .row--spaced > .row__column--retina--7 {
    width: calc(58.3333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--retina--offset--7 {
    margin-left: calc(58.3333% - var(--kayros_grid-gutter));
  }

  .row__column--retina--8 {
    width: 66.6667%;
  }

  .row__column--retina--offset--8 {
    margin-left: 66.6667%;
  }

  .row--spaced > .row__column--retina--8 {
    width: calc(66.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--retina--offset--8 {
    margin-left: calc(66.6667% - var(--kayros_grid-gutter));
  }

  .row__column--retina--9 {
    width: 75%;
  }

  .row__column--retina--offset--9 {
    margin-left: 75%;
  }

  .row--spaced > .row__column--retina--9 {
    width: calc(75% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--retina--offset--9 {
    margin-left: calc(75% - var(--kayros_grid-gutter));
  }

  .row__column--retina--10 {
    width: 83.3333%;
  }

  .row__column--retina--offset--10 {
    margin-left: 83.3333%;
  }

  .row--spaced > .row__column--retina--10 {
    width: calc(83.3333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--retina--offset--10 {
    margin-left: calc(83.3333% - var(--kayros_grid-gutter));
  }

  .row__column--retina--11 {
    width: 91.6667%;
  }

  .row__column--retina--offset--11 {
    margin-left: 91.6667%;
  }

  .row--spaced > .row__column--retina--11 {
    width: calc(91.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--retina--offset--11 {
    margin-left: calc(91.6667% - var(--kayros_grid-gutter));
  }

  .row__column--retina--12 {
    width: 100%;
  }

  .row__column--retina--offset--12 {
    margin-left: 100%;
  }

  .row--spaced > .row__column--retina--12 {
    width: calc(100% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--retina--offset--12 {
    margin-left: calc(100% - var(--kayros_grid-gutter));
  }
}

@media (width >= 3840px) {
  .row__column--4k--1 {
    width: 8.33333%;
  }

  .row__column--4k--offset--1 {
    margin-left: 8.33333%;
  }

  .row--spaced > .row__column--4k--1 {
    width: calc(8.33333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--4k--offset--1 {
    margin-left: calc(8.33333% - var(--kayros_grid-gutter));
  }

  .row__column--4k--2 {
    width: 16.6667%;
  }

  .row__column--4k--offset--2 {
    margin-left: 16.6667%;
  }

  .row--spaced > .row__column--4k--2 {
    width: calc(16.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--4k--offset--2 {
    margin-left: calc(16.6667% - var(--kayros_grid-gutter));
  }

  .row__column--4k--3 {
    width: 25%;
  }

  .row__column--4k--offset--3 {
    margin-left: 25%;
  }

  .row--spaced > .row__column--4k--3 {
    width: calc(25% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--4k--offset--3 {
    margin-left: calc(25% - var(--kayros_grid-gutter));
  }

  .row__column--4k--4 {
    width: 33.3333%;
  }

  .row__column--4k--offset--4 {
    margin-left: 33.3333%;
  }

  .row--spaced > .row__column--4k--4 {
    width: calc(33.3333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--4k--offset--4 {
    margin-left: calc(33.3333% - var(--kayros_grid-gutter));
  }

  .row__column--4k--5 {
    width: 41.6667%;
  }

  .row__column--4k--offset--5 {
    margin-left: 41.6667%;
  }

  .row--spaced > .row__column--4k--5 {
    width: calc(41.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--4k--offset--5 {
    margin-left: calc(41.6667% - var(--kayros_grid-gutter));
  }

  .row__column--4k--6 {
    width: 50%;
  }

  .row__column--4k--offset--6 {
    margin-left: 50%;
  }

  .row--spaced > .row__column--4k--6 {
    width: calc(50% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--4k--offset--6 {
    margin-left: calc(50% - var(--kayros_grid-gutter));
  }

  .row__column--4k--7 {
    width: 58.3333%;
  }

  .row__column--4k--offset--7 {
    margin-left: 58.3333%;
  }

  .row--spaced > .row__column--4k--7 {
    width: calc(58.3333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--4k--offset--7 {
    margin-left: calc(58.3333% - var(--kayros_grid-gutter));
  }

  .row__column--4k--8 {
    width: 66.6667%;
  }

  .row__column--4k--offset--8 {
    margin-left: 66.6667%;
  }

  .row--spaced > .row__column--4k--8 {
    width: calc(66.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--4k--offset--8 {
    margin-left: calc(66.6667% - var(--kayros_grid-gutter));
  }

  .row__column--4k--9 {
    width: 75%;
  }

  .row__column--4k--offset--9 {
    margin-left: 75%;
  }

  .row--spaced > .row__column--4k--9 {
    width: calc(75% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--4k--offset--9 {
    margin-left: calc(75% - var(--kayros_grid-gutter));
  }

  .row__column--4k--10 {
    width: 83.3333%;
  }

  .row__column--4k--offset--10 {
    margin-left: 83.3333%;
  }

  .row--spaced > .row__column--4k--10 {
    width: calc(83.3333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--4k--offset--10 {
    margin-left: calc(83.3333% - var(--kayros_grid-gutter));
  }

  .row__column--4k--11 {
    width: 91.6667%;
  }

  .row__column--4k--offset--11 {
    margin-left: 91.6667%;
  }

  .row--spaced > .row__column--4k--11 {
    width: calc(91.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--4k--offset--11 {
    margin-left: calc(91.6667% - var(--kayros_grid-gutter));
  }

  .row__column--4k--12 {
    width: 100%;
  }

  .row__column--4k--offset--12 {
    margin-left: 100%;
  }

  .row--spaced > .row__column--4k--12 {
    width: calc(100% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--4k--offset--12 {
    margin-left: calc(100% - var(--kayros_grid-gutter));
  }
}

@media (width >= 800px) {
  .row__column--8k--1 {
    width: 8.33333%;
  }

  .row__column--8k--offset--1 {
    margin-left: 8.33333%;
  }

  .row--spaced > .row__column--8k--1 {
    width: calc(8.33333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--8k--offset--1 {
    margin-left: calc(8.33333% - var(--kayros_grid-gutter));
  }

  .row__column--8k--2 {
    width: 16.6667%;
  }

  .row__column--8k--offset--2 {
    margin-left: 16.6667%;
  }

  .row--spaced > .row__column--8k--2 {
    width: calc(16.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--8k--offset--2 {
    margin-left: calc(16.6667% - var(--kayros_grid-gutter));
  }

  .row__column--8k--3 {
    width: 25%;
  }

  .row__column--8k--offset--3 {
    margin-left: 25%;
  }

  .row--spaced > .row__column--8k--3 {
    width: calc(25% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--8k--offset--3 {
    margin-left: calc(25% - var(--kayros_grid-gutter));
  }

  .row__column--8k--4 {
    width: 33.3333%;
  }

  .row__column--8k--offset--4 {
    margin-left: 33.3333%;
  }

  .row--spaced > .row__column--8k--4 {
    width: calc(33.3333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--8k--offset--4 {
    margin-left: calc(33.3333% - var(--kayros_grid-gutter));
  }

  .row__column--8k--5 {
    width: 41.6667%;
  }

  .row__column--8k--offset--5 {
    margin-left: 41.6667%;
  }

  .row--spaced > .row__column--8k--5 {
    width: calc(41.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--8k--offset--5 {
    margin-left: calc(41.6667% - var(--kayros_grid-gutter));
  }

  .row__column--8k--6 {
    width: 50%;
  }

  .row__column--8k--offset--6 {
    margin-left: 50%;
  }

  .row--spaced > .row__column--8k--6 {
    width: calc(50% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--8k--offset--6 {
    margin-left: calc(50% - var(--kayros_grid-gutter));
  }

  .row__column--8k--7 {
    width: 58.3333%;
  }

  .row__column--8k--offset--7 {
    margin-left: 58.3333%;
  }

  .row--spaced > .row__column--8k--7 {
    width: calc(58.3333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--8k--offset--7 {
    margin-left: calc(58.3333% - var(--kayros_grid-gutter));
  }

  .row__column--8k--8 {
    width: 66.6667%;
  }

  .row__column--8k--offset--8 {
    margin-left: 66.6667%;
  }

  .row--spaced > .row__column--8k--8 {
    width: calc(66.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--8k--offset--8 {
    margin-left: calc(66.6667% - var(--kayros_grid-gutter));
  }

  .row__column--8k--9 {
    width: 75%;
  }

  .row__column--8k--offset--9 {
    margin-left: 75%;
  }

  .row--spaced > .row__column--8k--9 {
    width: calc(75% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--8k--offset--9 {
    margin-left: calc(75% - var(--kayros_grid-gutter));
  }

  .row__column--8k--10 {
    width: 83.3333%;
  }

  .row__column--8k--offset--10 {
    margin-left: 83.3333%;
  }

  .row--spaced > .row__column--8k--10 {
    width: calc(83.3333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--8k--offset--10 {
    margin-left: calc(83.3333% - var(--kayros_grid-gutter));
  }

  .row__column--8k--11 {
    width: 91.6667%;
  }

  .row__column--8k--offset--11 {
    margin-left: 91.6667%;
  }

  .row--spaced > .row__column--8k--11 {
    width: calc(91.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--8k--offset--11 {
    margin-left: calc(91.6667% - var(--kayros_grid-gutter));
  }

  .row__column--8k--12 {
    width: 100%;
  }

  .row__column--8k--offset--12 {
    margin-left: 100%;
  }

  .row--spaced > .row__column--8k--12 {
    width: calc(100% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--8k--offset--12 {
    margin-left: calc(100% - var(--kayros_grid-gutter));
  }
}

@media (width >= 5000px) {
  .row__column--5k--1 {
    width: 8.33333%;
  }

  .row__column--5k--offset--1 {
    margin-left: 8.33333%;
  }

  .row--spaced > .row__column--5k--1 {
    width: calc(8.33333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--5k--offset--1 {
    margin-left: calc(8.33333% - var(--kayros_grid-gutter));
  }

  .row__column--5k--2 {
    width: 16.6667%;
  }

  .row__column--5k--offset--2 {
    margin-left: 16.6667%;
  }

  .row--spaced > .row__column--5k--2 {
    width: calc(16.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--5k--offset--2 {
    margin-left: calc(16.6667% - var(--kayros_grid-gutter));
  }

  .row__column--5k--3 {
    width: 25%;
  }

  .row__column--5k--offset--3 {
    margin-left: 25%;
  }

  .row--spaced > .row__column--5k--3 {
    width: calc(25% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--5k--offset--3 {
    margin-left: calc(25% - var(--kayros_grid-gutter));
  }

  .row__column--5k--4 {
    width: 33.3333%;
  }

  .row__column--5k--offset--4 {
    margin-left: 33.3333%;
  }

  .row--spaced > .row__column--5k--4 {
    width: calc(33.3333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--5k--offset--4 {
    margin-left: calc(33.3333% - var(--kayros_grid-gutter));
  }

  .row__column--5k--5 {
    width: 41.6667%;
  }

  .row__column--5k--offset--5 {
    margin-left: 41.6667%;
  }

  .row--spaced > .row__column--5k--5 {
    width: calc(41.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--5k--offset--5 {
    margin-left: calc(41.6667% - var(--kayros_grid-gutter));
  }

  .row__column--5k--6 {
    width: 50%;
  }

  .row__column--5k--offset--6 {
    margin-left: 50%;
  }

  .row--spaced > .row__column--5k--6 {
    width: calc(50% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--5k--offset--6 {
    margin-left: calc(50% - var(--kayros_grid-gutter));
  }

  .row__column--5k--7 {
    width: 58.3333%;
  }

  .row__column--5k--offset--7 {
    margin-left: 58.3333%;
  }

  .row--spaced > .row__column--5k--7 {
    width: calc(58.3333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--5k--offset--7 {
    margin-left: calc(58.3333% - var(--kayros_grid-gutter));
  }

  .row__column--5k--8 {
    width: 66.6667%;
  }

  .row__column--5k--offset--8 {
    margin-left: 66.6667%;
  }

  .row--spaced > .row__column--5k--8 {
    width: calc(66.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--5k--offset--8 {
    margin-left: calc(66.6667% - var(--kayros_grid-gutter));
  }

  .row__column--5k--9 {
    width: 75%;
  }

  .row__column--5k--offset--9 {
    margin-left: 75%;
  }

  .row--spaced > .row__column--5k--9 {
    width: calc(75% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--5k--offset--9 {
    margin-left: calc(75% - var(--kayros_grid-gutter));
  }

  .row__column--5k--10 {
    width: 83.3333%;
  }

  .row__column--5k--offset--10 {
    margin-left: 83.3333%;
  }

  .row--spaced > .row__column--5k--10 {
    width: calc(83.3333% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--5k--offset--10 {
    margin-left: calc(83.3333% - var(--kayros_grid-gutter));
  }

  .row__column--5k--11 {
    width: 91.6667%;
  }

  .row__column--5k--offset--11 {
    margin-left: 91.6667%;
  }

  .row--spaced > .row__column--5k--11 {
    width: calc(91.6667% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--5k--offset--11 {
    margin-left: calc(91.6667% - var(--kayros_grid-gutter));
  }

  .row__column--5k--12 {
    width: 100%;
  }

  .row__column--5k--offset--12 {
    margin-left: 100%;
  }

  .row--spaced > .row__column--5k--12 {
    width: calc(100% - var(--kayros_grid-gutter));
  }

  .row--spaced > .row__column--5k--offset--12 {
    margin-left: calc(100% - var(--kayros_grid-gutter));
  }
}

.hero {
  color: #fff;
  text-align: center;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  flex-flow: wrap;
  place-content: stretch center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.hero:before {
  content: "";
  background: linear-gradient(#00000080, #000c);
  position: absolute;
  inset: 0;
}

.hero--full {
  min-height: 100%;
}

.hero--light, .hero--transparent {
  color: #424242;
}

.hero--transparent:before {
  background: none;
}

.hero--light:before {
  color: #424242;
  background: linear-gradient(#ffffff80, #fffc);
}

.hero__content {
  z-index: 2;
  flex: none;
  align-self: auto;
  width: 90%;
}

@media screen and (width >= 37.5626em) {
  .hero {
    height: auto;
    min-height: 80%;
  }

  .hero--full {
    min-height: 100%;
  }
}

@media screen and (width >= 62em) {
  .hero {
    min-height: 50%;
  }

  .hero--full {
    min-height: 100%;
  }
}

.modal {
  z-index: 99;
  background: #00000080;
  width: 100%;
  height: 100%;
  transition: all .5s;
  display: none;
  position: absolute;
}

.modal--active {
  display: block;
}

.modal--transparent {
  background-color: #0000;
}

.modal__content {
  text-align: center;
  background-color: #fff;
  width: 90%;
  max-height: 100%;
  padding: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: auto;
  transform: translate(-50%, -50%);
}

@media (width >= 601px) {
  .modal__content {
    width: 80%;
  }
}

@media (width >= 992px) {
  .modal__content {
    width: 70%;
  }
}

@media (width >= 1200px) {
  .modal__content {
    width: auto;
  }
}

.vertical {
  flex-direction: column;
  display: flex;
}

.horizontal {
  display: flex;
}

.horizontal--left {
  justify-content: flex-start;
}

.horizontal--center {
  justify-content: center;
  align-items: center;
}

.horizontal--right {
  justify-content: flex-end;
}

.vertical--left {
  align-items: flex-start;
}

.vertical--center {
  align-items: center;
}

.vertical--right {
  align-items: flex-end;
}

.bottom {
  margin: 0;
  position: absolute;
  bottom: 0;
}

.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center {
  margin: 0 auto;
  left: 0;
  right: 0;
}

.left {
  left: 0;
}

.right {
  right: 0;
}

.text--center {
  text-align: center;
}

.text--left {
  text-align: left;
}

.text--right {
  text-align: right;
}

.text--justify {
  text-align: justify;
}

.padding {
  padding: 1rem;
}

.margin {
  margin: 1px;
}

.padding--2 {
  padding: 2rem;
}

.margin--2 {
  margin: 2rem;
}

.padding--3 {
  padding: 3rem;
}

.margin--3 {
  margin: 3rem;
}

.padding--4 {
  padding: 4rem;
}

.margin--4 {
  margin: 4rem;
}

.padding--5 {
  padding: 5rem;
}

.margin--5 {
  margin: 5rem;
}

.nav {
  text-align: right;
  z-index: 90;
  background-color: #fff;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  max-width: 100%;
  min-height: 3rem;
  display: flex;
}

.nav--fixed {
  max-height: 100%;
  position: fixed;
  overflow: auto;
}

.nav--rl {
  text-align: left;
}

.nav__content-list__item {
  cursor: pointer;
  text-align: center;
  background: inherit;
  width: 100%;
  list-style: none;
  display: flex;
  overflow: visible;
}

.nav__content-list__item > * {
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
}

.nav__content-list__item:hover {
  background-color: #eee;
}

.nav__content-list__item:empty {
  padding: 1rem;
}

.nav__content-list__item__icon {
  align-self: center;
  width: 1.5rem;
  min-width: 1.5rem;
  max-width: 100%;
  height: 60%;
  max-height: 100%;
  margin: 0 .3rem;
}

.nav--side__content-list {
  display: none;
}

.nav__menu-icon {
  cursor: pointer;
  align-self: center;
  max-width: 5%;
}

.nav__header {
  text-align: left;
  max-width: 95%;
  max-height: 3.5rem;
  font-size: inherit;
  width: 100%;
  height: 3.5rem;
  min-height: 100%;
  display: flex;
}

.nav__header__logo {
  align-self: center;
  max-width: 80%;
  height: 100%;
  max-height: 80%;
  padding: 0 .5rem;
}

.nav__header__title {
  align-self: center;
  margin: 0 0 0 .1rem;
  font-size: 1.5rem;
}

.nav__content-list {
  align-items: center;
  width: auto;
  max-height: 100%;
  margin: 0;
  padding: 0;
  display: none;
}

.nav__content-list--active {
  background: inherit;
  flex-flow: column;
  width: 100%;
  display: flex;
  overflow: auto;
}

.wrapper--top-navigation {
  margin-top: 3.5rem;
}

@media (width >= 601px) {
  .nav--top--phablet {
    flex-wrap: nowrap;
    display: flex;
    overflow: hidden;
  }

  .nav--top--phablet .nav__menu-icon {
    display: none;
  }

  .nav--top--phablet .nav__content-list {
    max-height: 3.5rem;
    display: flex;
  }

  .nav--top--phablet .nav__content-list .nav__content-list__item {
    height: 100%;
  }

  .nav--top--phablet .nav__content-list .nav__content-list__item > * {
    justify-content: center;
  }

  .nav--top--phablet .nav__content-list--full {
    justify-content: center;
    width: 100%;
    height: 3.5rem;
    display: flex;
  }

  .nav--top--phablet .nav__content-list--full .nav__content-list__item {
    height: 100%;
  }

  .nav--top--phablet .nav__content-list--full .nav__content-list__item > * {
    justify-content: center;
  }

  .nav--side--phablet {
    float: left;
    flex-flow: column;
    width: 30%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
  }

  .nav--side--phablet .nav__header {
    height: auto;
    min-height: initial;
    max-height: initial;
    border-bottom: 1px solid #eee;
    flex-flow: column;
    flex-shrink: 0;
    max-width: 100%;
    padding-bottom: .5rem;
  }

  .nav--side--phablet .nav__header__logo {
    max-height: 10rem;
    margin: .5rem 0;
  }

  .nav--side--phablet .nav__header__title {
    text-align: center;
  }

  .nav--side--phablet .nav__menu-icon {
    display: none;
  }

  .nav--side--phablet .nav__content-list {
    flex-flow: column;
    display: flex;
    overflow: auto;
  }

  .nav--side--phablet .nav__content-list .nav__content-list__item {
    width: 100%;
    height: auto;
    min-height: 2rem;
  }

  .nav--side--phablet .nav__content-list .nav__content-list__item > * {
    justify-content: flex-start;
    width: 100%;
  }

  .nav--side--phablet .nav__content-list .nav__content-list__item .nav__content-list__item__icon {
    height: 2rem;
    min-height: 1.5rem;
    max-height: 100%;
    line-height: 1.4rem;
  }

  .wrapper--side-navigation--phablet {
    float: right;
    width: 70%;
    margin-top: 0;
  }
}

@media (width >= 992px) {
  .nav--top--tablet {
    flex-wrap: nowrap;
    display: flex;
    overflow: hidden;
  }

  .nav--top--tablet .nav__menu-icon {
    display: none;
  }

  .nav--top--tablet .nav__content-list {
    max-height: 3.5rem;
    display: flex;
  }

  .nav--top--tablet .nav__content-list .nav__content-list__item {
    height: 100%;
  }

  .nav--top--tablet .nav__content-list .nav__content-list__item > * {
    justify-content: center;
  }

  .nav--top--tablet .nav__content-list--full {
    justify-content: center;
    width: 100%;
    height: 3.5rem;
    display: flex;
  }

  .nav--top--tablet .nav__content-list--full .nav__content-list__item {
    height: 100%;
  }

  .nav--top--tablet .nav__content-list--full .nav__content-list__item > * {
    justify-content: center;
  }

  .nav--side--tablet {
    float: left;
    flex-flow: column;
    width: 20%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
  }

  .nav--side--tablet .nav__header {
    height: auto;
    min-height: initial;
    max-height: initial;
    border-bottom: 1px solid #eee;
    flex-flow: column;
    flex-shrink: 0;
    max-width: 100%;
    padding-bottom: .5rem;
  }

  .nav--side--tablet .nav__header__logo {
    max-height: 10rem;
    margin: .5rem 0;
  }

  .nav--side--tablet .nav__header__title {
    text-align: center;
  }

  .nav--side--tablet .nav__menu-icon {
    display: none;
  }

  .nav--side--tablet .nav__content-list {
    flex-flow: column;
    display: flex;
    overflow: auto;
  }

  .nav--side--tablet .nav__content-list .nav__content-list__item {
    width: 100%;
    height: auto;
    min-height: 2rem;
  }

  .nav--side--tablet .nav__content-list .nav__content-list__item > * {
    justify-content: flex-start;
    width: 100%;
  }

  .nav--side--tablet .nav__content-list .nav__content-list__item .nav__content-list__item__icon {
    height: 2rem;
    min-height: 1.5rem;
    max-height: 100%;
    line-height: 1.4rem;
  }

  .wrapper--side-navigation--tablet {
    float: right;
    width: 80%;
    margin-top: 0;
  }
}

@media (width >= 1200px) {
  .nav--top--desktop {
    flex-wrap: nowrap;
    display: flex;
    overflow: hidden;
  }

  .nav--top--desktop .nav__menu-icon {
    display: none;
  }

  .nav--top--desktop .nav__content-list {
    max-height: 3.5rem;
    display: flex;
  }

  .nav--top--desktop .nav__content-list .nav__content-list__item {
    height: 100%;
  }

  .nav--top--desktop .nav__content-list .nav__content-list__item > * {
    justify-content: center;
  }

  .nav--top--desktop .nav__content-list--full {
    justify-content: center;
    width: 100%;
    height: 3.5rem;
    display: flex;
  }

  .nav--top--desktop .nav__content-list--full .nav__content-list__item {
    height: 100%;
  }

  .nav--top--desktop .nav__content-list--full .nav__content-list__item > * {
    justify-content: center;
  }

  .nav--side--desktop {
    float: left;
    flex-flow: column;
    width: 20%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
  }

  .nav--side--desktop .nav__header {
    height: auto;
    min-height: initial;
    max-height: initial;
    border-bottom: 1px solid #eee;
    flex-flow: column;
    flex-shrink: 0;
    max-width: 100%;
    padding-bottom: .5rem;
  }

  .nav--side--desktop .nav__header__logo {
    max-height: 10rem;
    margin: .5rem 0;
  }

  .nav--side--desktop .nav__header__title {
    text-align: center;
  }

  .nav--side--desktop .nav__menu-icon {
    display: none;
  }

  .nav--side--desktop .nav__content-list {
    flex-flow: column;
    display: flex;
    overflow: auto;
  }

  .nav--side--desktop .nav__content-list .nav__content-list__item {
    width: 100%;
    height: auto;
    min-height: 2rem;
  }

  .nav--side--desktop .nav__content-list .nav__content-list__item > * {
    justify-content: flex-start;
    width: 100%;
  }

  .nav--side--desktop .nav__content-list .nav__content-list__item .nav__content-list__item__icon {
    height: 2rem;
    min-height: 1.5rem;
    max-height: 100%;
    line-height: 1.4rem;
  }

  .wrapper--side-navigation--desktop {
    float: right;
    width: 80%;
    margin-top: 0;
  }
}

@media (width >= 1900px) {
  .nav--top--desktop-large {
    flex-wrap: nowrap;
    display: flex;
    overflow: hidden;
  }

  .nav--top--desktop-large .nav__menu-icon {
    display: none;
  }

  .nav--top--desktop-large .nav__content-list {
    max-height: 3.5rem;
    display: flex;
  }

  .nav--top--desktop-large .nav__content-list .nav__content-list__item {
    height: 100%;
  }

  .nav--top--desktop-large .nav__content-list .nav__content-list__item > * {
    justify-content: center;
  }

  .nav--top--desktop-large .nav__content-list--full {
    justify-content: center;
    width: 100%;
    height: 3.5rem;
    display: flex;
  }

  .nav--top--desktop-large .nav__content-list--full .nav__content-list__item {
    height: 100%;
  }

  .nav--top--desktop-large .nav__content-list--full .nav__content-list__item > * {
    justify-content: center;
  }

  .nav--side--desktop-large {
    float: left;
    flex-flow: column;
    width: 15%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
  }

  .nav--side--desktop-large .nav__header {
    height: auto;
    min-height: initial;
    max-height: initial;
    border-bottom: 1px solid #eee;
    flex-flow: column;
    flex-shrink: 0;
    max-width: 100%;
    padding-bottom: .5rem;
  }

  .nav--side--desktop-large .nav__header__logo {
    max-height: 10rem;
    margin: .5rem 0;
  }

  .nav--side--desktop-large .nav__header__title {
    text-align: center;
  }

  .nav--side--desktop-large .nav__menu-icon {
    display: none;
  }

  .nav--side--desktop-large .nav__content-list {
    flex-flow: column;
    display: flex;
    overflow: auto;
  }

  .nav--side--desktop-large .nav__content-list .nav__content-list__item {
    width: 100%;
    height: auto;
    min-height: 2rem;
  }

  .nav--side--desktop-large .nav__content-list .nav__content-list__item > * {
    justify-content: flex-start;
    width: 100%;
  }

  .nav--side--desktop-large .nav__content-list .nav__content-list__item .nav__content-list__item__icon {
    height: 2rem;
    min-height: 1.5rem;
    max-height: 100%;
    line-height: 1.4rem;
  }

  .wrapper--side-navigation--desktop-large {
    float: right;
    width: 85%;
    margin-top: 0;
  }
}

button, input, optgroup, select, textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  border: none;
  outline: none;
  margin: 0;
}

optgroup {
  font-weight: 700;
}

button, input, select, textarea {
  color: #424242;
  background: #f5f5f5;
  border-radius: 3px;
  padding: 1rem;
  overflow: visible;
}

input, select, textarea, form button {
  padding: .5rem 1rem;
}

label {
  margin: .5rem;
}

button, select {
  text-transform: none;
  cursor: pointer;
  border-radius: 3px;
  margin: .5rem;
  padding: .5rem 1rem;
}

button:hover {
  background-color: #e8e8e8;
}

.link--button {
  text-transform: none;
  cursor: pointer;
  border-radius: 3px;
  margin: .5rem;
  padding: .5rem 1rem;
}

.link--button:hover {
  background-color: #e8e8e8;
}

.link--button {
  color: #424242;
  background: #f5f5f5;
  display: inline-block;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35rem .625rem .75rem;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

textarea {
  resize: none;
  overflow: auto;
}

[type="color"] {
  padding: 0;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

input[type="range"]::-webkit-slider-runnable-track {
  cursor: pointer;
  background: #00000080;
}

input[type="range"]::-moz-range-track {
  cursor: pointer;
  background: #00000080;
}

input[type="range"]::-ms-fill-lower {
  cursor: pointer;
  background: #00000080;
}

input[type="range"]:focus::-ms-fill-lower {
  cursor: pointer;
  background: #00000080;
}

input[type="range"]::-ms-fill-upper {
  cursor: pointer;
  background: #00000080;
}

input[type="range"]:focus::-ms-fill-upper {
  cursor: pointer;
  background: #00000080;
}

input[type="range"] {
  -webkit-appearance: none;
  background: none;
  outline: none;
}

.circle {
  background: #ddd;
  border-radius: 50%;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}

.circle__content {
  color: #fff;
  float: left;
  text-align: center;
  width: 100%;
  margin-top: -.5rem;
  padding-top: 50%;
  line-height: 1;
}

code, kbd, pre, samp {
  font-family: monospace;
  font-size: 1rem;
}

pre {
  background: #f5f5f5;
  padding: 1rem;
  overflow: auto;
}

pre code {
  display: block;
  overflow: hidden;
}

code[class*="language-"], pre[class*="language-"] {
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  tab-size: 4;
  -webkit-hyphens: none;
  hyphens: none;
  direction: ltr;
  font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
  font-size: .85em;
  line-height: 1.5;
}

pre[class*="language-"] {
  background: 0 0 / 3em 3em content-box border-box;
  border: 1px solid #4242421c;
  margin: .5em 0;
  position: relative;
  overflow: auto visible;
}

code[class*="language"] {
  max-height: inherit;
  height: 100%;
  padding: 0 1em;
  display: block;
  overflow: visible;
}

:not(pre) > code[class*="language-"] {
  color: #c92c2c;
  border: 1px solid #0000001a;
  border-radius: .3em;
  padding: .2em;
  position: relative;
}

:not(pre) > code[class*="language-"]:after, pre[class*="language-"]:after {
  left: auto;
  right: .75em;
  transform: rotate(2deg);
}

.token.comment, .token.block-comment, .token.prolog, .token.doctype {
  color: #878787;
  font-style: italic;
}

.token.cdata {
  color: #bb2500;
}

.token.punctuation {
  color: #5f6364;
}

.token.boolean {
  color: #007ab7;
}

.token.string {
  color: #dd2400;
}

.token.function {
  color: #5c99ad;
}

.token.property, .token.tag {
  color: #0045aa;
}

.token.number {
  color: #007ab7;
}

.token.function-name, .token.constant, .token.symbol, .token.deleted {
  color: #c92c2c;
}

.token.attr-name {
  color: #007ab7;
}

.token.selector, .token.char, .token.builtin, .token.inserted {
  color: #2f9c0a;
}

.token.operator {
  color: #006ff8;
}

.token.url {
  color: #004ccb;
  text-decoration: none;
}

.token.entity {
  color: #007ab7;
  cursor: help;
}

.token.variable {
  color: #8735a5;
}

.token.keyword {
  color: #0045aa;
  font-weight: 400;
}

.token.atrule {
  color: #de7a31;
  font-style: normal;
  font-weight: 700;
}

.token.attr-value {
  color: #5d8f00;
  font-weight: 400;
}

.token.class-name {
  color: #1990b8;
}

.token.regex, .token.important {
  color: #e90;
}

.language-css .token.string, .style .token.string {
  color: #f4378f;
}

.language-css .token.property, .style .token.property {
  color: #0045aa;
}

.language-css .token.number, .style .token.number {
  color: #dd2400;
}

.language-css .token.hexcode, .style .token.hexcode {
  color: #d72d65;
}

.token.important {
  font-weight: 400;
}

.token.bold {
  font-weight: 700;
}

.token.italic {
  font-style: italic;
}

.namespace {
  opacity: .7;
}

@media screen and (width <= 767px) {
  pre[class*="language-"]:before, pre[class*="language-"]:after {
    box-shadow: none;
    bottom: 14px;
  }
}

.token.tab:not(:empty):before, .token.cr:before, .token.lf:before {
  color: #e0d7d1;
}

pre[class*="language-"][data-line] {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
}

pre[data-line] code {
  padding-left: 4em;
  position: relative;
}

pre .line-highlight {
  margin-top: 0;
}

.token a {
  color: inherit;
}

code[class*="language-"] a[href], pre[class*="language-"] a[href] {
  cursor: help;
  text-decoration: none;
}

code[class*="language-"] a[href]:hover, pre[class*="language-"] a[href]:hover {
  cursor: help;
  text-decoration: underline;
}

.card {
  border: 1px solid #bababa40;
}

.card--2 {
  box-shadow: 0 6px 6px #bababa40;
}

.card--3 {
  box-shadow: 0 9px 9px #bababa40;
}

.card--4 {
  box-shadow: 0 12px 12px #bababa40;
}

.card--5 {
  box-shadow: 0 15px 15px #bababa40;
}

.card--6 {
  box-shadow: 0 18px 18px #bababa40;
}

.separator--material {
  border: 1px solid #ddd;
  border-bottom: 0;
  margin: 0 0 .5rem;
}

.button--floating {
  z-index: 9;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  margin: 1.5rem;
  position: absolute;
  bottom: 0;
  right: 0;
  box-shadow: 0 2px 5px #00000029, 0 2px 10px #0000001f;
}

@font-face {
  font-family: Ubuntu;
  font-display: fallback;
  src: url("Ubuntu-L.533f8963.ttf");
  font-weight: lighter;
}

:root {
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light dark;
  --color-background: linear-gradient(90deg, #f5f5f5 1em, transparent 1%) center, linear-gradient(#f5f5f5 1em, transparent 1%) center, #bbb;
  --color-text: #424242;
}

@media (prefers-color-scheme: dark) {
  :root {
    --lightningcss-light: ;
    --lightningcss-dark: initial;
    --color-text: #ddd;
    --color-background: linear-gradient(90deg, #222 1em, transparent 1%) center, linear-gradient(#222 1em, transparent 1%) center, #424242;
  }
}

body {
  background: var(--color-background);
  color: var(--color-text);
  background-size: 1.1em 1.1em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: Ubuntu, Open-Sans, sans-serif;
  transition: all 1s;
  display: flex;
}

h1 {
  text-align: center;
  margin: 0;
  font-size: 5em;
  font-weight: lighter;
}

h2 {
  text-align: center;
  font-size: 3em;
  font-style: italic;
  font-weight: lighter;
}

small {
  font-size: .8rem;
  display: block;
}
/*# sourceMappingURL=index.1443ecd8.css.map */
